import { getCommonParamsItemLabel } from '@/utils/common'
import store from '@/store'

export const contentTableConfig = {
  propList: [
    {
      prop: 'name',
      label: 'file.picture-name'
    },
    {
      prop: 'tenantId',
      label: 'user.tenant',
      width: 180,
      formatter: (row) => {
        return row ? store.getters.tenantDataMap[row] : ''
      }
    },
    {
      prop: 'status',
      label: 'common.app-status',
      width: 120,
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'status')
      }
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time',
      width: '180'
    },
    {
      prop: 'options',
      label: 'param.param-operation',
      width: '380',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
